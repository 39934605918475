rm-loading-progress {
    position: absolute;
    display: block;
    width: 100%;
    height: 4px;
    bottom: 0;
    background-color: transparent;

    &[active] {
        background-size: 4px 4px;
        background-image: linear-gradient(
            135deg, 
            var(--peppermint) 25%, 
            transparent 25%, 
            transparent 50%, 
            var(--peppermint) 50%, 
            var(--peppermint) 75%, 
            transparent 75%, 
            transparent
            );
        animation: animate-stripes 1s linear infinite;
    }
}

@keyframes animate-stripes {
    0% {
        background-position: 0 0;
    }

    100% {
        background-position: 40px 0;
    }
}